import React, { useEffect, useState } from 'react';
import {
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
  TopToolbar,
  ExportButton,
  FilterButton,
  useDataProvider,
  downloadCSV,
} from 'react-admin';
import EnumTextField from '../common_modules/components/EnumTextField';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import jsonExport from 'jsonexport/dist';

const ListActions = ({ exporter }: { exporter: (records: any[]) => void }) => (
  <TopToolbar>
    <FilterButton />
    <ExportButton label="CSVダウンロード" exporter={exporter} />
  </TopToolbar>
);

const CustomClientInput = (props: any) => {
  const [choices, setChoices] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  useEffect(() => {
    const fetchClients = async () => {
      const { data } = await dataProvider.getList('clients', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { applyStatus: 2 }
      });
      setChoices(data);
    };

    fetchClients();
  }, [dataProvider]);

  return (
    <SelectInput
      {...props}
      source="clientId"
      choices={choices}
      optionText="name"
      label={translate('resources.orders.fields.clientId')}
      emptyText="(選択解除)"
    />
  );
};

export const OrderList = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const handleExport = async (records: any[]) => {
    if (!records || records.length === 0) {
      return;
    }

    const clientIds = records.map(record => record.clientId);
    const { data: relatedClients } = await dataProvider.getMany('clients', { ids: clientIds });

    // データの変換と翻訳を行う
    const dataToExport = records.map(record => {
      const transformedRecord: Record<string, any> = { ...record };
      // clientIdの名前を取得して置き換える
      const clientName = relatedClients.find(client => client.id === record.clientId)?.name || '';
      transformedRecord.clientId = clientName;

      return transformedRecord;
    });

    // 最初の項目を無視してカラム名を翻訳
    const keys = Object.keys(records[0]).slice(1); // 最初の項目を無視
    const headers = keys.map(key => translate(`resources.orders.fields.${key}`, { _: key }));

    // JSONをCSVに変換してダウンロード
    jsonExport(
      dataToExport.map(record => {
        // 最初の項目を無視し、翻訳されたカラム名をキーとしてデータを再構築
        const transformedRecord: Record<string, any> = {};
        keys.forEach((key, index) => {
          const translatedKey = headers[index]; // 翻訳されたキーを使用
          transformedRecord[translatedKey] = record[key];
        });
        return transformedRecord;
      }),
      { headers },
      (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        const bom = '\uFEFF';
        const csvWithBom = bom + csv;
        downloadCSV(csvWithBom, '注文');
      }
    );
  };


  return (
    <List
      actions={<ListActions exporter={handleExport} />}
      filters={[
        <SearchInput key="orderId" source="orderId" placeholder={translate('resources.orders.fields.orderId')} alwaysOn />,
        <ReferenceInput
          key="clientId"
          source="clientId"
          reference="clients"
          alwaysOn
        >
          <CustomClientInput />
        </ReferenceInput>, <SearchInput key="affiliateId" source="affiliateId" placeholder={translate('resources.orders.fields.affiliateId')} />,
        <SearchInput key="ecSiteClientId" source="ecSiteClientId" placeholder={translate('resources.orders.fields.ecSiteClientId')} />
      ]}
      sort={{ field: 'orderId', order: 'DESC' }}
    >
      <FlexibleListLayout rowClick={false} bulkActionButtons={false}>
        <ReferenceField source="clientId" reference="clients">
          <TextField source="name" label={translate('resources.orders.fields.name')} />
        </ReferenceField>
        <TextField source="orderId" label={translate('resources.orders.fields.orderId')} />
        <TextField
          source="ecSiteClientId"
          label={translate('resources.orders.fields.ecSiteClientId')}
          render={(record: Record<string, any>) => record.ecSiteClientId || "-"}
        />
        <TextField source="affiliateId" />
        <DateField showTime={true} source="orderDate" />
        <NumberField source="price" />
        <EnumTextField source="isCancelled" translationKeyPrefix="values.isCancelled" />
      </FlexibleListLayout>
    </List>
  );
};


export const OrderShow = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceField source="clientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="orderId" />
      <TextField source="ecSiteClientId" />
      <TextField source="affiliateId" />
      <DateField showTime={true} source="orderDate" />
      <NumberField source="price" />
      <EnumTextField source="isCancelled" translationKeyPrefix="values.isCancelled" />
    </SimpleShowLayout>
  </Show>
);
